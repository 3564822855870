/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    AnalyticsEventCategories,
    AnalyticsEventNames,
    AnalyticsEventPayloads,
    AnalyticsEvents,
    EventPayloadCreators,
    GoogleAnalyticsEcommerceEventObj,
    ShoppingCartEvents,
} from '../eventTypes';
import { eventPayloadCreators } from './eventPayloadCreators';
import { version } from 'lib/global';

const createDefaultOptions = ({
    country,
    language,
}: {
    country: string;
    language: string;
}) =>
    ({
        version: `List ${version}`,
        site_market: country,
        site_language: language,
        page_type: 'onlineshoppinglist',
        event_label: 'favourites',
    } as const);

const debugEvent = (options: any, type = 'logEvent') => {
    if (localStorage && localStorage?.getItem('debugFav')) {
        console.log(
            `DEBUG MODE: ${type}() is sending window.sendEvent(options)`,
            options
        );
    }
};

export const createEventHandler =
    ({
        country,
        language,
        currency,
    }: {
        country: string;
        language: string;
        currency: string;
    }) =>
    <
        C extends AnalyticsEventCategories,
        A extends AnalyticsEventNames<C>,
        P extends AnalyticsEvents[C][A]
    >(
        category: C,
        action: A,
        payload: Array<
            P extends (arg: any) => AnalyticsEventPayloads<C, A>
                ? Parameters<P>[0]
                : AnalyticsEventPayloads<C, A>
        >
    ): void => {
        if (
            typeof window !== 'undefined' &&
            typeof window.sendEvent === 'function'
        ) {
            const settings = {
                ...createDefaultOptions({ country, language }),
                event_action: action,
                event_category: category,
            };
            const params = {
                currency,
            };
            if (
                category in eventPayloadCreators &&
                action in
                    eventPayloadCreators[category as keyof EventPayloadCreators]
            ) {
                const payloadCreator = (eventPayloadCreators as any)[category][
                    action
                ]; // Casting to any since we know at this point there is a payloadCreator matching this category and action.
                payload.forEach(p => {
                    const event = payloadCreator(p);
                    const options = {
                        ...settings,
                        ...event,
                        params,
                    };
                    debugEvent(options);
                    window.sendEvent<C, A, P>(options);
                });
            } else {
                // If there is no payloadCreator, we can assume the payload is already formatted correctly as per window.sendEvent.
                payload.forEach(p => {
                    const options = {
                        ...settings,
                        ...p,
                        params,
                    };
                    debugEvent(options);
                    window.sendEvent(options);
                });
            }
        } else {
            console.warn(
                'Trying to run logEvent in a non browser environment.'
            );
        }
    };

export const createEcommerceEventHandler =
    ({ country, language }: { country: string; language: string }) =>
    <N extends keyof ShoppingCartEvents, P extends ShoppingCartEvents[N]>(
        name: N,
        payload: P
    ): void => {
        const { event_label, ...defaultOptions } = createDefaultOptions({
            country,
            language,
        });
        if (
            typeof window !== 'undefined' &&
            typeof window.sendEvent === 'function'
        ) {
            const event: GoogleAnalyticsEcommerceEventObj = {
                name,
                ...payload,
                custom: {
                    ...defaultOptions,
                    ...(payload?.custom || {}),
                },
            };
            debugEvent(event, 'logEcommerceEvent');
            window.sendEvent(event);
        } else {
            console.warn(
                'Trying to run logEvent in a non browser environment.'
            );
        }
    };

/** Convenience wrapper for logEvents which takes a single payload rather than an array. */
export const createLogEvent =
    (logEvents: ReturnType<typeof createEventHandler>) =>
    <
        C extends AnalyticsEventCategories,
        A extends AnalyticsEventNames<C>,
        P extends AnalyticsEvents[C][A]
    >(
        category: C,
        action: A,
        payload: P extends (arg: any) => AnalyticsEventPayloads<C, A>
            ? Parameters<P>[0]
            : AnalyticsEventPayloads<C, A>
    ) => {
        logEvents(category, action, [payload]);
    };
