import React from 'react';

import styles from './VisualPlaceholder.module.scss';
import image from '@ingka/ssr-icon/paths/image';
import SSRIcon from '@skapa/SSRIcon';
import { clsx } from 'clsx';

type VisualPlaceholderProps = {
    className?: string;
};

const VisualPlaceholder: React.FC<VisualPlaceholderProps> = ({ className }) => {
    return (
        <div className={clsx(styles.wrapper, className)}>
            <div className={styles.innerWrapper}>
                <SSRIcon paths={image} />
            </div>
        </div>
    );
};

export default VisualPlaceholder;
